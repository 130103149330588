/* eslint-disable react/jsx-no-target-blank */
import * as React from "react"
import { useClasses } from '../lib/ReactUtils'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import '../styles/TwoCols'

import GOOGLE from '../images/download/google.svg'
import APPLE from '../images/download/apple.svg'
import HUAWEI from '../images/download/huawei.png'

// windows binary

export default function DownloadPage() {
  const root = useClasses()
  return (
    <Layout full={false}>
      <SEO title='Download' description='Download OneOffice mobile and desktop apps'/>
      <main className="main" ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">Downloads</h1>
        <h2 className="subtitle">Work on the go or offline</h2>




        <div className="twocols-tight mt-40" id="client">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/download/oo-drive.png" alt="Editor"/>
          </div>
          <div className="twocols-col-text-left">
            <div className="subheading">OneOffice Drive</div>
            <div className="heading">Your Files at your fingertips</div>
            <div className="description">
              <p>This application allows you to sync your files on your mobile device, including uploading, downloading and mobile sync.</p>
              <p>You can also select which files to keep versions of locally.</p>
              <p className="quote">Make sure you allow notifications so you get all the messages from your OneOffice server and stay in touch.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-15 lg:gap-x-20 mt-10 w-full">
          <div className='w-full md:w-1/3 md:px-3 text-center'>
            <a href="https://play.google.com/store/apps/details?id=com.oneoffice.client" target="_blank" alt="OneOffice Drive on GooglePlay"><img src={GOOGLE} alt='Google'/></a>
          </div>
          <div className='w-full md:w-1/3 text-center'>
            <a href="https://geo.itunes.apple.com/us/app/oneoffice-drive/id1596703177" target="_blank" alt="OneOffice Drive on iOS AppStore"><img src={APPLE} alt='Apple'/></a>
          </div>
          <div className='w-full md:w-1/3 text-center'>
            <a href="https://appgallery.huawei.com/app/C104984123" target="_blank" alt="OneOffice Drive on Huawei AppGallery"><img src={HUAWEI} alt='Huawei'/></a>
          </div>
        </div>


        <div className="twocols-tight mt-50" id="meet">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/download/oo-talk.png" alt="Editor"/>
          </div>
          <div className="twocols-col-text-right">
            <div className="subheading">OneOffice Meet</div>
            <div className="heading">Don't miss a (secure) call anymore</div>
            <div className="description">
              <p>This application allows you to talk and chat real-time with colleagues and partners.</p>
              <p>With built-in end-to-end encryption, all your communications are 100% secure by design.</p>
              <p className="quote">Make sure you allow notifications so your phone rings when calls or chats come in.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-15 lg:gap-x-20 mt-10 w-full">
            <div className='w-full md:w-1/3 md:px-3 text-center'>
              <a href="https://play.google.com/store/apps/details?id=com.oneoffice.meet" target="_blank" alt="OneOffice Drive on GooglePlay"><img src={GOOGLE} alt='Google'/></a>
            </div>
            <div className='w-full md:w-1/3 text-center'>
              <a href="https://geo.itunes.apple.com/us/app/oneoffice-meet/id1593449335" target="_blank" alt="OneOffice Drive on iOS AppStore"><img src={APPLE} alt='Apple'/></a>
            </div>
            <div className='w-full md:w-1/3 text-center'>
              <a href="https://appgallery.huawei.com/app/C104984345" target="_blank" alt="OneOffice Drive on Huawei AppGallery"><img src={HUAWEI} alt='Huawei'/></a>
            </div>
        </div>


        <div className="twocols-tight mt-50" id="meet">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/download/oo-auth.png" alt="Authofization"/>
          </div>
          <div className="twocols-col-text-left">
            <div className="subheading">OneOffice Auth</div>
            <div className="heading">Secure Multi-Factor Authorization</div>
            <div className="description">
              <p>This application allows you to login to your OneOffice instance with a secure authentication protocol.</p>
              <p>Note: iOS application coming shortly.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-stretch gap-y-4 md:gap-x-15 lg:gap-x-20 mt-10 w-full">
            <div className='w-full md:w-1/3 md:px-3 text-center'>
              <a href="https://play.google.com/store/apps/details?id=com.oneoffice.auth" target="_blank" alt="OneOffice Drive on GooglePlay"><img src={GOOGLE} alt='Google'/></a>
            </div>
            <div className='flex w-full md:w-1/3 items-center justify-center h-30 md:h-auto border-2 border-black rounded-xl'>
              <span className='text-lg px-3 text-center'>iPhone / iPad Application coming soon</span>
            </div>
            <div className='w-full md:w-1/3 text-center'>
              <a href="https://appgallery.huawei.com/app/C106568227" target="_blank" alt="OneOffice Drive on Huawei AppGallery"><img src={HUAWEI} alt='Huawei'/></a>
            </div>
        </div>



        <div className="twocols-tight mt-50" id="editor">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/download/oo-editor.png" alt="Editor"/>
          </div>
          <div className="twocols-col-text-right">
            <div className="subheading">OneOffice Productivity Suite v7.5</div>
            <div className="heading">A complete document editor for your desktop</div>
            <div className="description">
              <p>
                OO-Editor is a full document editor (Word, Excel, PowerPoint, Visio and all other standard formats).
                It also includes a full SQL-compliant database (just like Access) and an Equation Editor.
                A must have for the modern team! 
                <span className="text-base text-gray-700"> -- Note: MacOS version coming soon!</span>
              </p>
              <p className="quote">If you haven't yet installed the Microsoft VC++ redistributable libraries, <a href="https://aka.ms/vs/17/release/vc_redist.x64.exe" target="_blank">click here to download from Microsoft</a> or get it yourself from <a href="https://docs.microsoft.com/en-us/cpp/windows/latest-supported-vc-redist?view=msvc-170" target="_blank">microsoft.com</a></p>
            </div>
          </div>
        </div>
        <div>
          <div className='w-full my-15 text-center'>
            <a href="/binaries/OO-Editor_7.5.0_Win_x86-64.msi" target="_blank" className='button cta lg'>Download for Windows NOW</a>
          </div>
        </div>





        <div className="twocols-tight mt-50" id="sync">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/download/oo-sync.png" alt="Editor"/>
          </div>
          <div className="twocols-col-text-left">
            <div className="subheading">OneOffice Sync v3.5.82</div>
            <div className="heading">Sync your files on your desktop</div>
            <div className="description">
              <p>
                OO-Sync allows you to synchronize your files real-time so you can work locally or on the go!
                You can also launch any OneOffice application (e.g. mail, files) with one click!
                <span className="text-base text-gray-700"> -- Note: MacOS version coming soon!</span>
              </p>
              <div className='w-full my-15 text-center'>
                <a href="/binaries/OO-Sync_3.5.82_Win_x64.msi" target="_blank" className='button cta lg'>Download for Windows NOW</a>
              </div>
            </div>
          </div>
        </div>



        <div className="twocols-tight mt-50" id="mail">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/download/oo-mail.png" alt="Mail"/>
          </div>
          <div className="twocols-col-text-right">
            <div className="subheading">OneOfficeMail v1.10.5</div>
            <div className="heading">Full email client on your desktop</div>
            <div className="description">
              <p>The smartest email program on the planet!
                Runs in the background so not to drain your battery. Comes packed with phishing detection, real-time notifications, folders and more ...
              </p>
              <p>
                OneOfficeMail does not need administrator privileges to install, as it runs from your local directory.
              </p>
              <span className="text-base text-gray-700"> -- Note: MacOS version coming soon!</span>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <div className='w-full my-15 text-center'>
            <a href="/binaries/OO-Mail_1.10.5.exe" target="_blank" className='button cta lg'>Download for Windows NOW</a>
          </div>
        </div>

        
      </main>
    </Layout>

  )
}
